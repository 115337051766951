<template>
  <div>
    <v-card :loading="isLoading" class="mb-2">
      <v-card-title>
        <v-icon>mdi-table</v-icon>
        Banka Hesap Ekstre Raporu
      </v-card-title>
      <v-card-subtitle>
        {{ $t("helper_text.reports.bank_balance_activities") }}
      </v-card-subtitle>

      <v-card-text v-if="clusterId">
        <v-form @submit.prevent="handleFormSubmit" ref="form">
          <v-row dense>
            <v-col md="3">
              <rs-datepicker
                label="Başlangıç Tarihi"
                v-model="formData.date.min"
                :rules="[rules.maxDate(formData.date.min, formData.date.max)]"
                required
              />
            </v-col>

            <v-col md="3">
              <rs-datepicker
                label="Bitiş Tarihi"
                v-model="formData.date.max"
                :rules="[rules.minDate(formData.date.max, formData.date.min)]"
                required
              />
            </v-col>

            <v-col md="3">
              <rs-select-bank-account
                label="Banka Hesabı"
                v-model="formData.bank_account_id"
                :rules="[rules.required]"
                :cluster-id="clusterId"
              />
            </v-col>

            <v-col md="3">
              <v-btn
                :loading="isLoading"
                :disabled="isLoading"
                color="primary"
                type="submit"
                class="ms-3"
              >
                Göster
              </v-btn>
            </v-col>
          </v-row>
        </v-form>

        <p v-if="iframeUrl" class="mt-3 mb-0 text-h5">
          <a :href="iframeUrl" download="rapor.pdf" target="_blank">
            Raporu cihazınıza indirmek için tıklayın.
          </a>
        </p>
        <iframe
          class="mt-3"
          :src="iframeUrl"
          frameborder="0"
          v-if="iframeUrl"
          style="width: 100%; height: 140vh"
        ></iframe>
      </v-card-text>

      <v-card-text v-else>
        Raporu görebilmek için üst menüden toplu yaşam alanı seçin.
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import { hasForm, hasDataTable } from "@/view/mixins";

export default {
  mixins: [hasForm, hasDataTable],
  computed: {
    ...mapGetters(["clusterId", "cluster"]),
  },
  data() {
    const date = new Date();
    const min = new Date(
      Date.UTC(date.getUTCFullYear(), date.getUTCMonth() - 1, 1)
    );
    const max = new Date();

    return {
      iframeUrl: null,
      reportData: null,
      formData: {
        date: {
          min: min.toJSON().split("T")[0],
          max: max.toJSON().split("T")[0],
        },
        bank_account_id: null,
      },
    };
  },
  methods: {
    handleFormSubmit() {
      if (this.isLoading || !this.$refs.form.validate()) {
        this.$toast.error("Lütfen tüm gerekli alanları doldurunuz");
        return false;
      }

      if (this.formData.date.min > this.formData.date.max) {
        this.$toast.error("Başlangıç tarihi bitiş tarihinden önce olmalıdır");
        return;
      }

      // this.formData.action_on = this.formData.date;

      // const params = Object.assign(this.buildSearchParams(this.formData));
      this.iframeUrl = null;
      this.isLoading = true;
      let minDate = new Date(this.formData.date.min);
      minDate.setDate(minDate.getDate() - 1);

      const params = {
        per_page: 1,
        order_by: "order_num",
        order: "desc",
        bank_account_id: this.formData.bank_account_id,
        cluster_id: this.clusterId,
        action_on: {
          max: minDate.toJSON().split("T")[0],
        },
        extended_description: true,
      };

      // First we get the sum of activities before minimum date to write
      // "transferred" row
      return this.$api
        .query("balance-activities", { params })
        .then((response) => {
          const reportData = {
            cluster: this.cluster.name,
            start_date: new Date(this.formData.date.min).toLocaleDateString(),
            end_date: new Date(this.formData.date.max).toLocaleDateString(),
            bank_account_name: null,
            balance: 0,
            debt_total: 0,
            due_total: 0,
            list: [],
            logo_url: null,
          };

          if (this.cluster.active_company?.company?.logo?.url) {
            reportData.logo_url = this.cluster.active_company.company?.logo.url;
          } else if (this.cluster.report_logo?.url) {
            reportData.logo_url = this.cluster.report_logo.url;
          }

          if (response.data.data.length) {
            const balanceItem = response.data.data[0];
            let debt = 0.0;
            let due = 0.0;

            if (balanceItem.bank_safe_balance < 0) {
              due = balanceItem.bank_safe_balance * -1;
            } else {
              debt = balanceItem.bank_safe_balance;
            }

            reportData.list.push({
              action_on: new Date(this.formData.date.min).toLocaleDateString(),
              balance_no: "Devir",
              debt: this.numberToLocaleFormat(debt),
              debt_number: debt,
              due: this.numberToLocaleFormat(due),
              due_number: due,
              balance: this.numberToLocaleFormat(balanceItem.bank_safe_balance),
              description: "Devir",
              is_transferred: true,
            });

            reportData.bank_account_name = balanceItem.bank_safe;
          }

          return reportData;
        })
        .then((reportData) => {
          // Then we get the activity list

          const params = {
            per_page: 10000,
            order_by: "order_num",
            order: "asc",
            bank_account_id: this.formData.bank_account_id,
            cluster_id: this.clusterId,
            action_on: this.formData.date,
            extended_description: true,
          };

          return this.$api
            .query("balance-activities", { params })
            .then((response) => {
              if (!response.data.data.length) {
                return reportData;
              } else {
                reportData.bank_account_name = response.data.data[0].bank_safe;
              }

              // push the activities to the list
              for (const index in response.data.data) {
                const item = response.data.data[index];
                let description = item.description_ext;

                if (!description) {
                  description = item.description;
                } else {
                  description += " / " + item.description;
                }

                reportData.list.push({
                  action_on: new Date(item.action_on).toLocaleDateString(),
                  balance_no: item.balance_no,
                  document_no: item.document_no,
                  description: description,
                  debt: this.numberToLocaleFormat(item.bank_safe_debt || 0.0),
                  due: this.numberToLocaleFormat(item.bank_safe_due || 0.0),
                  balance: this.numberToLocaleFormat(item.bank_safe_balance),
                });
              }

              // calculate debt and due totals
              if (!reportData.list.length) {
                reportData.debt_total = 0;
                reportData.due_total = 0;
              } else if (reportData.list[0].is_transferred === true) {
                reportData.debt_total = this.numberToLocaleFormat(
                  ((response.data.meta.totals.bank_safe_debt || 0.0) * 100 +
                    reportData.list[0].debt_number * 100) /
                    100
                );
                reportData.due_total = this.numberToLocaleFormat(
                  ((response.data.meta.totals.bank_safe_due || 0.0) * 100 +
                    reportData.list[0].due_number * 100) /
                    100
                );
              } else {
                reportData.debt_total = this.numberToLocaleFormat(
                  response.data.meta.totals.bank_safe_debt || 0.0
                );
                reportData.due_total = this.numberToLocaleFormat(
                  response.data.meta.totals.bank_safe_due || 0.0
                );
              }

              return reportData;
            });
        })
        .then((reportData) => {
          // Now we need to get current balance
          const params = {
            per_page: 1,
            order_by: "order_num",
            order: "desc",
            bank_account_id: this.formData.bank_account_id,
            cluster_id: this.clusterId,
          };

          return this.$api
            .query("balance-activities", { params })
            .then((response) => {
              if (response.data.data.length) {
                reportData.balance = this.numberToLocaleFormat(
                  response.data.data[0].bank_safe_balance
                );
              }

              return reportData;
            });
        })
        .then((reportData) => {
          const params = {
            file_type: "pdf",
            print_template: "BalanceActivity-BankAccount",
            receiptData: reportData,
            copies: 1,
          };

          return this.$api.post("print-file", params).then((response) => {
            this.iframeUrl = response.data.data.url;
          });
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
  },
};
</script>
